import { Grid, SelectChangeEvent, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { TrainingService } from "../api/TrainingService";
import { TrainingModuleCard } from "../components/TrainingModuleCard/TrainingModuleCard";
import { Select } from "../components/ui/Select/Select";
import useTranslation from "../hooks/useTranslation";
import TrainingModule from "../models/trainingModule";
import { TrainingModuleResponse } from "../types/training";
import {
  filterTrainingModules,
  generateFilterItems,
  sortTrainingModules,
} from "../utils/Training.utils";
import "./TrainingPage.scss";

const loadingTrainingModules: TrainingModuleResponse = {
  reference_id: "",
  title: "",
  description: "",
  duration: "",
  thumbnail: "",
  tags: [""],
  audience: "",
  touchpoints: [
    {
      title: "",
      description: "",
      order: 1,
      duration: "",
      reference_id: "",
      thumbnail: "",
      preview_link: "",
    },
  ],
  languages: [""],
  is_ready: false,
  toolbox: "",
  can_view: false,
  can_download: false,
  suggested_order: 0,
};

export const loadingTrainingModulesMock = [
  loadingTrainingModules,
  loadingTrainingModules,
].map((module) => new TrainingModule(module));

export const TrainingPage = () => {
  const { t } = useTranslation("trainingPage");
  const trainingService = new TrainingService();
  const [trainingModules, setTrainingModules] = useState<TrainingModule[]>(
    loadingTrainingModulesMock
  );
  const [selectedAudiences, setSelectedAudiences] = useState<string[]>([]);
  const [selectedLanguages, setSelectedLanguages] = useState<string[]>([]);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  const { data: trainingModulesData, error: trainingModulesError } = useQuery<
    TrainingModuleResponse[],
    AxiosError
  >(["trainingModules"], () => trainingService.getTrainingModules(), {
    enabled: true,
  });

  const { activeModules, inactiveModules } =
    sortTrainingModules(trainingModules);

  const { languageItems, audienceItems, tagItems } = generateFilterItems(
    trainingModules,
    t
  );

  const handleAudienceChange = (event: SelectChangeEvent<unknown>) => {
    setSelectedAudiences(event.target.value as string[]);
  };

  const handleLanguageChange = (event: SelectChangeEvent<unknown>) => {
    const selected = event.target.value as string[];

    if (selected.includes(t("filter.selectAll"))) {
      if (selected.length === languageItems.length) {
        setSelectedLanguages([]);
      } else {
        setSelectedLanguages(languageItems.slice(1));
      }
    } else {
      setSelectedLanguages(event.target.value as string[]);
    }
  };

  const handleTagChange = (event: SelectChangeEvent<unknown>) => {
    const selected = event.target.value as string[];

    if (selected.includes(t("filter.selectAll"))) {
      if (selected.length === tagItems.length) {
        setSelectedTags([]);
      } else {
        setSelectedTags(tagItems.slice(1));
      }
    } else {
      setSelectedTags(event.target.value as string[]);
    }
  };

  const filteredModules = filterTrainingModules(
    activeModules,
    selectedAudiences,
    selectedLanguages,
    selectedTags
  );

  useEffect(() => {
    if (trainingModulesData) {
      const trainingModulesFromResponse = trainingModulesData.map(
        (module) => new TrainingModule(module)
      );

      setTrainingModules(trainingModulesFromResponse);
    }
  }, [trainingModulesData]);

  useEffect(() => {
    if (trainingModulesError) {
      setTrainingModules([]);
    }
  }, [trainingModulesError]);

  return (
    <div className="TrainingPage">
      {!!trainingModulesError && (
        <Typography variant="h2">{t("modules.error")}</Typography>
      )}
      <div className="TrainingPage__filters">
        <Select
          className="TrainingPage__filters__select"
          labelId="training-audience-select-label"
          id="training-audience-select"
          value={selectedAudiences}
          onChange={handleAudienceChange}
          label={t("filter.audience")}
          items={audienceItems}
          multiple
          renderAsChips
        />

        <Select
          className="TrainingPage__filters__select"
          labelId="training-language-select-label"
          id="training-language-select"
          value={selectedLanguages}
          onChange={handleLanguageChange}
          label={t("filter.language")}
          items={languageItems}
          multiple
          renderAsChips
        />
        <Select
          className="TrainingPage__filters__select"
          labelId="training-tags-select-label"
          id="training-tags-select"
          value={selectedTags}
          onChange={handleTagChange}
          label={t("filter.tags")}
          items={tagItems}
          multiple
          renderAsChips
        />
      </div>
      <Grid container spacing={3} className="TrainingPage__container">
        {filteredModules?.map((trainingModule, i) => (
          <Grid item xs={12} sm={6} md={3} key={`${trainingModule.title}-${i}`}>
            <TrainingModuleCard
              trainingModule={trainingModule}
              isActive={true}
            />
          </Grid>
        ))}
        {selectedAudiences.length === 0 &&
          selectedLanguages.length === 0 &&
          selectedTags.length === 0 &&
          inactiveModules?.map((trainingModule, i) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              key={`${trainingModule.title}-${i}`}
            >
              <TrainingModuleCard
                trainingModule={trainingModule}
                isActive={false}
              />
            </Grid>
          ))}
      </Grid>
    </div>
  );
};

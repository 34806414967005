import { format } from "date-fns";
import { enGB, enUS, is } from "date-fns/locale";

interface AvailableLanguages {
  [key: string]: Locale;
}
// TODO: use available languages string literal from LanguageProvider
// that way we don't have to update this file when we add a new language.
export const availableLanguages: AvailableLanguages = {
  "is-is": is,
  "en-us": enUS,
  "en-gb": enGB,
};

export const capitalizeFirstLetter = (label: string) => {
  return label.charAt(0).toUpperCase() + label.slice(1);
};

export const formatToCurrency = (
  value: number,
  currency: string,
  maxDigits = 0,
  locale = window.navigator.language
) => {
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
    maximumFractionDigits: maxDigits,
  }).format(value);
};

export const getFormattedDate = (
  date: Date | string,
  t: (str: string) => string,
  language?: string,
  datePattern?: string
): string => {
  const translateTolanguage = language || "en-GB";
  let formatToDatePattern = datePattern || "LLL dd, yyyy";

  if (language === "is-IS" && formatToDatePattern === "LLL dd, yyyy") {
    formatToDatePattern = "dd LLL yyyy";
  }

  if (/^\d{2}\/\d{2}\/\d{4}$/.test(date.toString())) {
    date = parseDate(date);
  }

  try {
    return format(new Date(date), formatToDatePattern, {
      locale: availableLanguages[translateTolanguage],
    });
  } catch {
    return t("invalidDate");
  }
};

export const setFormattedDateAPI = (
  date: Date | string,
  t: (str: string) => string
): string => {
  let formatToDatePattern = "yyyy-MM-dd hh:mm:ss";

  const parsedDate = parseDate(date);

  try {
    return format(parsedDate, formatToDatePattern);
  } catch {
    return t("invalidDate");
  }
};

export const parseDate = (date: Date | string): Date => {
  const [day, month, year] = date.toString().split("/");
  return new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
};

export const highlightPercentages = (
  text: string,
  className: string
): string => {
  // This regex matches any "word" (sequence of non-whitespace characters) that contains a '%'
  return text.replace(/(\S*%\S*)/g, `<span class="${className}">$1</span>`);
};

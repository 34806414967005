import { ListSubheader, MenuItem, SelectChangeEvent } from "@mui/material";
import useTranslation from "../../hooks/useTranslation";
import {
  GroupedSurveyDemographicScopes,
  OVERVIEW_FILTER_ID,
} from "../../utils/WorkplaceCulture.utils";
import { Select } from "../ui/Select/Select";
import "./WorkplaceCultureDemographicFilter.scss";

interface WorkplaceCultureDemographicFilterProps {
  demographicFilters?: GroupedSurveyDemographicScopes;
  onChange: (e: SelectChangeEvent<unknown>) => void;
  selectedValue?: string;
  disabled?: boolean;
}
export const WorkplaceCultureDemographicFilter = ({
  demographicFilters,
  onChange,
  selectedValue,
  disabled = true,
}: WorkplaceCultureDemographicFilterProps) => {
  const { t } = useTranslation("workplaceCulturePage");

  const renderSelectFilter = (groupId: string) => {
    const groups = demographicFilters?.[groupId].map((groupFilter) => {
      return (
        <MenuItem key={groupFilter.id} value={groupFilter.id}>
          {groupFilter.label}
        </MenuItem>
      );
    });
    return [<ListSubheader>{groupId}</ListSubheader>, groups];
  };

  return (
    <Select
      labelId="wp-demographic-filter-selector"
      id="wp-demographic-filter-selector"
      value={selectedValue}
      onChange={onChange}
      defaultValue={selectedValue}
      disabled={disabled}
      className="WorkplaceCultureDemographicFilter"
    >
      {!disabled && (
        <MenuItem value={OVERVIEW_FILTER_ID}>
          {t("scale.demographicFilter.default")}
        </MenuItem>
      )}
      {demographicFilters &&
        Object.keys(demographicFilters).map((groupId) =>
          renderSelectFilter(groupId)
        )}
    </Select>
  );
};

import { Skeleton } from "@mui/material";
import useTranslation from "../../hooks/useTranslation";
import { RecommendationIllustration } from "../../styles/illustrations/RecommendationIllustration";
import { SurveyChapterInsight } from "../../types/workplaceCulture";
import { InsightsSlide } from "../InsightsSlide/InsightsSlide";
import { NoDataState } from "../NoDataState/NoDataState";
import Carousel from "../ui/Carousel/Carousel";
import { DashboardWidget } from "../ui/DashboardWidget/DashboardWidget";
import { mockInsightsWidgetData } from "./InsightsWidget.data";
import "./InsightsWidget.scss";

interface InsightsWidgetProps {
  insightsData?: SurveyChapterInsight[];
  insightsError?: boolean;
  insightsLoading?: boolean;
  noInsights?: boolean;
  preview?: boolean;
  surveyClosed?: boolean;
}

export const InsightsWidget = ({
  insightsData,
  insightsError: error = false,
  insightsLoading: isLoading = true,
  noInsights = false,
  preview = false,
  surveyClosed = false,
}: InsightsWidgetProps) => {
  const { t: tDashboard } = useTranslation("dashboardPage");
  const { t: tWorkplaceCulture } = useTranslation("workplaceCulturePage");

  let insights = undefined;
  if (insightsData && !preview) {
    insights = insightsData;
  } else if (preview) {
    insights = mockInsightsWidgetData;
  }

  const renderErrorState = () => {
    let title = tWorkplaceCulture("insights.noInsights.title");
    let info = tWorkplaceCulture("insights.noInsights.error");

    if (noInsights && surveyClosed) {
      info = tWorkplaceCulture("insights.noInsights.notEnoughData");
    } else if (noInsights && !surveyClosed) {
      info = tWorkplaceCulture("insights.noInsights.notAvailable");
    }

    return <NoDataState title={title} info={info} />;
  };

  return (
    <DashboardWidget
      title={tDashboard("insightsWidget.title")}
      icon={RecommendationIllustration}
      preview={preview}
    >
      {Array.isArray(insights) && !noInsights && (
        <div className="InsightsWidget">
          <Carousel>
            {insights.map((insight: SurveyChapterInsight) => (
              <InsightsSlide
                insight={insight}
                key={insight.id}
                needsTranslation={preview}
              />
            ))}
          </Carousel>
        </div>
      )}
      {isLoading && !preview && <Skeleton height={100} />}
      {!preview &&
        (error || !Array.isArray(insights) || noInsights) &&
        !isLoading &&
        renderErrorState()}
    </DashboardWidget>
  );
};

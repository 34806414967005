import { Skeleton } from "@mui/material";
import classNames from "classnames";
import useTranslation from "../../hooks/useTranslation";
import {
  SurveyChapterInsight,
  SurveyChapterScoring,
} from "../../types/workplaceCulture";
import { ProtectedRoutes } from "../../utils/routes.utils";
import { InsightsSlide } from "../InsightsSlide/InsightsSlide";
import { NoDataState } from "../NoDataState/NoDataState";
import { ScoreArc } from "../ScoreArc/ScoreArc";
import Carousel from "../ui/Carousel/Carousel";
import { DashboardWidget } from "../ui/DashboardWidget/DashboardWidget";
import { Paper } from "../ui/Paper/Paper";
import { Typography } from "../ui/Typography/Typography";
import { Card } from "./../ui/Card/Card";
import "./ChapterLinkCard.scss";

interface ChapterLinkCardProps {
  chapter: SurveyChapterScoring;
  isLoading?: boolean;
  insights?: SurveyChapterInsight[];
  surveyClosed?: boolean;
}

export const ChapterLinkCard = ({
  chapter,
  isLoading = true,
  insights,
  surveyClosed = false,
}: ChapterLinkCardProps) => {
  const { t } = useTranslation("workplaceCulturePage");

  const classes = classNames("ChapterLinkCard", {
    "ChapterLinkCard--loading": isLoading,
  });

  if (isLoading) {
    return (
      <Card className={classes}>
        <Skeleton variant="text" height={80} width={"90%"} />
        <Skeleton variant="rectangular" height={100} width={"70%"} />
        <Skeleton variant="text" height={80} width={"90%"} />
      </Card>
    );
  }

  const renderNotEnoughData = () => (
    <Card>
      <NoDataState title={t("surveyChapterOverview.notEnoughData")} info={""} />
    </Card>
  );

  return (
    <DashboardWidget
      title={chapter.name}
      icon={chapter.image}
      href={`${ProtectedRoutes.WORKPLACE_CULTURE}/${chapter.id}`}
    >
      <div className="ChapterLinkCard">
        {!chapter.demographic.scores.total_score && renderNotEnoughData()}
        {chapter.demographic.scores.total_score && (
          <div className="ChapterLinkCard__arc">
            <ScoreArc
              score={chapter.demographic.scores.total_score}
              label={t("surveyChapterOverview.scoreInfo")}
              size="medium"
            />
          </div>
        )}
        <Paper className="ChapterLinkCard__content">
          {(!insights || insights.length === 0) && (
            <Typography tagVariant="p" desktop="body2">
              {surveyClosed
                ? t("insights.noInsights.notEnoughData")
                : t("insights.noInsights.notAvailable")}
            </Typography>
          )}
          {insights && insights?.length > 0 && (
            <Typography
              tagVariant="p"
              desktop="body1"
              weight="bold"
              className="ChapterLinkCard__content__title"
            >
              {t("insights.title")}
            </Typography>
          )}
          <div className="ChapterLinkCard__content__insights">
            {insights && insights.length > 0 && (
              <Carousel disableAutoSlide>
                {insights.map((insight) => (
                  <InsightsSlide
                    insight={insight}
                    needsTranslation={false}
                    compact
                  />
                ))}
              </Carousel>
            )}
          </div>
        </Paper>
      </div>
    </DashboardWidget>
  );
};

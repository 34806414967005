import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import classNames from "classnames";
import { Children, ReactNode, useEffect, useRef, useState } from "react";
import "./Carousel.scss";

interface CarouselProps {
  children: ReactNode;
  interval?: number;
  disableAutoSlide?: boolean;
}

enum CarouselDirection {
  Next = "next",
  Prev = "prev",
}

const Carousel = ({
  children,
  interval = 10000,
  disableAutoSlide = false,
}: CarouselProps) => {
  const childrenArray = Children.toArray(children);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [exitingIndex, setExitingIndex] = useState<number | null>(null);
  const [incomingIndex, setIncomingIndex] = useState<number | null>(null);
  const [direction, setDirection] = useState<CarouselDirection>(
    CarouselDirection.Next
  );
  const intervalRef = useRef<NodeJS.Timeout | null>(null); // Needed for resetting auto slide when user clicks buttons
  const currentIndexRef = useRef(currentIndex); // Needed for auto slide to work correctly

  useEffect(() => {
    currentIndexRef.current = currentIndex;
  }, [currentIndex]);

  useEffect(() => {
    if (disableAutoSlide) return;
    startAutoSlide();
    return () => stopAutoSlide();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interval]);

  const startAutoSlide = () => {
    if (disableAutoSlide) return;
    stopAutoSlide(); // Clear any existing intervals
    intervalRef.current = setInterval(() => {
      changeSlide(CarouselDirection.Next);
    }, interval);
  };

  const stopAutoSlide = () => {
    if (disableAutoSlide) return;
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  const changeSlide = (newDirection: CarouselDirection) => {
    setDirection(newDirection);
    setExitingIndex(currentIndexRef.current); // Mark the current slide as exiting

    // Calculate the incoming index
    const newIndex =
      newDirection === CarouselDirection.Next
        ? (currentIndexRef.current + 1) % childrenArray.length
        : currentIndexRef.current === 0
        ? childrenArray.length - 1
        : currentIndexRef.current - 1;

    setIncomingIndex(newIndex); // Mark the new slide as incoming

    setTimeout(() => {
      // After the transition, finalize the new slide as the current slide
      setExitingIndex(null);
      setIncomingIndex(null);
      setCurrentIndex(newIndex);
    }, 300); // Match the CSS transition duration (0.5s)
  };

  const handlePrev = () => {
    changeSlide(CarouselDirection.Prev);
    startAutoSlide();
  };

  const handleNext = () => {
    changeSlide(CarouselDirection.Next);
    startAutoSlide();
  };

  return (
    <div className="Carousel">
      <div className="Carousel__content">
        <div
          className="Carousel__content__button Carousel__content__button--prev"
          onClick={handlePrev}
        >
          <KeyboardArrowLeftIcon color="secondary" />
        </div>
        <div className="Carousel__content__slide">
          {childrenArray.map((child, index) => (
            <div
              key={index}
              className={classNames("Carousel__content__slide__item", {
                "Carousel__content__slide__item--active-next":
                  index === currentIndex &&
                  direction === CarouselDirection.Next,
                "Carousel__content__slide__item--active-prev":
                  index === currentIndex &&
                  direction === CarouselDirection.Prev,
                "Carousel__content__slide__item--exiting-next":
                  index === exitingIndex &&
                  direction === CarouselDirection.Next,
                "Carousel__content__slide__item--exiting-prev":
                  index === exitingIndex &&
                  direction === CarouselDirection.Prev,
                "Carousel__content__slide__item--incoming-next":
                  index === incomingIndex &&
                  direction === CarouselDirection.Next,
                "Carousel__content__slide__item--incoming-prev":
                  index === incomingIndex &&
                  direction === CarouselDirection.Prev,
              })}
            >
              {child}
            </div>
          ))}
        </div>
        <div
          className="Carousel__content__button Carousel__content__button--next"
          onClick={handleNext}
        >
          <KeyboardArrowRightIcon color="secondary" />
        </div>
      </div>

      <div className="Carousel__indicators">
        {childrenArray.map((_, index) => (
          <div
            key={index}
            className={classNames("Carousel__indicators__item", {
              "Carousel__indicators__item--active": index === currentIndex,
            })}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default Carousel;

import TrainingModule from "../models/trainingModule";
import Gradient1 from "../styles/illustrations/gradients/gradient_1.png";
import Gradient2 from "../styles/illustrations/gradients/gradient_2.png";
import Gradient3 from "../styles/illustrations/gradients/gradient_3.png";
import Gradient4 from "../styles/illustrations/gradients/gradient_4.png";
import Gradient5 from "../styles/illustrations/gradients/gradient_5.png";

const GRADIENTS = [Gradient1, Gradient2, Gradient3, Gradient4, Gradient5];

interface SortedTrainingModules {
  activeModules: TrainingModule[];
  inactiveModules: TrainingModule[];
}

export const assignThumbnails = (
  modules: TrainingModule[]
): TrainingModule[] => {
  return modules.map((module, index) => {
    module.thumbnail = GRADIENTS[index % GRADIENTS.length];
    return module;
  });
};

export const sortTrainingModules = (
  trainingModules: TrainingModule[]
): SortedTrainingModules => {
  const sortedModules = [...trainingModules].sort((a, b) => {
    // If suggested_order is not set, put it at the end
    const orderA = a.suggested_order ?? Number.MAX_SAFE_INTEGER;
    const orderB = b.suggested_order ?? Number.MAX_SAFE_INTEGER;
    return orderA - orderB;
  });

  const activeModules = sortedModules.filter((obj) => obj.isAccessible());
  const inactiveModules = sortedModules.filter((obj) => !obj.isAccessible());

  assignThumbnails(activeModules);
  assignThumbnails(inactiveModules);

  return { activeModules, inactiveModules };
};

export const filterTrainingModules = (
  modules: TrainingModule[],
  selectedAudiences: string[],
  selectedLanguages: string[],
  selectedTags: string[]
): TrainingModule[] => {
  return modules.filter((module) => {
    return (
      (selectedAudiences.length === 0 ||
        selectedAudiences.includes(module.audience)) && // include both since they never co-exist
      (selectedLanguages.length === 0 ||
        selectedLanguages.every((language) =>
          module.languages.includes(language)
        )) &&
      (selectedTags.length === 0 ||
        selectedTags.every((tag) => module.tags.includes(tag)))
    );
  });
};

export const generateFilterItems = (
  trainingModules: TrainingModule[],
  t: (str: string) => string
) => {
  const languageItems = [
    t("filter.selectAll"),
    ...Array.from(
      new Set(trainingModules.flatMap((module) => module.languages))
    ),
  ];

  const audienceItems = [t("filter.management")];

  const tagItems = [
    t("filter.selectAll"),
    ...Array.from(new Set(trainingModules.flatMap((module) => module.tags))),
  ];

  return { languageItems, audienceItems, tagItems };
};

import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { SurveyService } from "../api/SurveyService";
import { WorkplaceCultureService } from "../api/WorkplaceCultureService";
import { ChapterOverview } from "../components/ChapterOverview.tsx/ChapterOverview";
import { EmptyState } from "../components/EmptyState/EmptyState";
import { SurveyDemographics } from "../components/SurveyDemographics/SurveyDemographics";
import { SurveyStatus } from "../components/SurveyStatus/SurveyStatus";
import { WorkplaceCultureOverview } from "../components/WorkplaceCultureOverview/WorkplaceCultureOverview";
import { Card } from "../components/ui/Card/Card";
import useTranslation from "../hooks/useTranslation";
import SurveyDispatch from "../models/surveyDispatch";
import { GetSurveyDispatchResponse } from "../types/survey";
import {
  GetSurveyChapterInsightResponse,
  GetSurveyChapterScoringResponse,
  GetSurveyDemographicResponse,
  GetSurveyScoringDemographicResponse,
} from "../types/workplaceCulture";
import { OVERVIEW_FILTER_ID } from "../utils/WorkplaceCulture.utils";
import { ProtectedRoutes } from "../utils/routes.utils";

export const WorkplaceCulturePage = () => {
  const { t } = useTranslation("workplaceCulturePage");
  const surveyService = new SurveyService();
  const workplaceCultureService = new WorkplaceCultureService();
  const [surveyDispatch, setSurveyDispatch] = useState<SurveyDispatch>();
  const [selectedDemographicFilter, setSelectedDemographicFilter] =
    useState<string>(OVERVIEW_FILTER_ID);

  const {
    data: surveyDispatchData,
    isLoading: surveyDispatchLoading,
    error: surveyDispatchError,
    refetch: handleSurveyDispatchRefetch,
  } = useQuery<GetSurveyDispatchResponse, AxiosError>(["surveyDispatch"], () =>
    surveyService.getSurveyDispatch()
  );

  useEffect(() => {
    if (surveyDispatchData) {
      setSurveyDispatch(new SurveyDispatch(surveyDispatchData));
    }
  }, [surveyDispatchData]);

  const {
    data: surveyDemographicsData,
    isLoading: demographicsLoading,
    error: demographicsError,
  } = useQuery<GetSurveyDemographicResponse, AxiosError>(
    ["surveyDemographics"],
    () =>
      workplaceCultureService.getSurveyDemographics(
        surveyDispatchData?.id || ""
      ),
    {
      enabled: !!surveyDispatchData?.id,
    }
  );

  const {
    data: surveyScoringData,
    isLoading: surveyScoringLoading,
    error: surveyScoringError,
    refetch: surveyScoringRefetch,
  } = useQuery<GetSurveyScoringDemographicResponse, AxiosError>(
    [["surveyScoring", { filterId: selectedDemographicFilter }]],
    () =>
      workplaceCultureService.getSurveyScoring(
        surveyDispatchData?.id || "",
        selectedDemographicFilter
      ),
    {
      enabled: !!surveyDispatchData?.id,
    }
  );

  const {
    data: surveyChapterScoringData,
    isLoading: surveyChapterScoringLoading,
    error: surveyChapterScoringError,
  } = useQuery<GetSurveyChapterScoringResponse, AxiosError>(
    ["surveyChapterScoring", { filterId: selectedDemographicFilter }],
    () =>
      workplaceCultureService.getSurveyChapterScoring(
        surveyDispatchData?.id || "",
        selectedDemographicFilter
      ),
    {
      enabled: !!surveyDispatchData?.id,
    }
  );

  const { data: surveyChapterInsightsData } = useQuery<
    GetSurveyChapterInsightResponse,
    AxiosError
  >(
    ["surveyChapterInsights"],
    () =>
      workplaceCultureService.getSurveyChapterInsights(
        surveyDispatchData?.id || ""
      ),
    {
      enabled: !!surveyDispatchData?.id,
    }
  );

  const renderSurvey = () => {
    if (surveyDispatchError?.response?.status === 404) {
      return (
        <>
          <SurveyStatus
            survey={surveyDispatch}
            isLoading={surveyDispatchLoading}
            noSurvey={surveyDispatchError?.response?.status === 404}
            error={
              !!surveyDispatchError &&
              surveyDispatchError.response?.status !== 404
            }
            onSurveyUpdate={handleSurveyDispatchRefetch}
          />
        </>
      );
    }

    const handleSurveyScoringRefetch = (filterId: string) => {
      setSelectedDemographicFilter(filterId);
      surveyScoringRefetch();
    };

    return (
      <>
        <SurveyStatus
          survey={surveyDispatch}
          isLoading={surveyDispatchLoading}
          noSurvey={surveyDispatchError?.response?.status === 404}
          error={
            !!surveyDispatchError &&
            surveyDispatchError.response?.status !== 404
          }
          onSurveyUpdate={handleSurveyDispatchRefetch}
        />
        {!surveyDispatchLoading && !surveyDispatchError && (
          <>
            <WorkplaceCultureOverview
              scoringData={surveyScoringData}
              isLoading={surveyScoringLoading}
              error={!!surveyScoringError}
              insightsData={surveyChapterInsightsData}
              hasEnoughAnswers={surveyScoringData?.scores.total_score !== null}
              onScoringRefetch={handleSurveyScoringRefetch}
              selectedDemographicFilter={selectedDemographicFilter}
            />

            <ChapterOverview
              chapterScoringData={surveyChapterScoringData}
              isLoading={surveyChapterScoringLoading}
              error={!!surveyChapterScoringError}
              chapterInsightsData={surveyChapterInsightsData}
              surveyClosed={surveyDispatch?.isClosed()}
            />

            <SurveyDemographics
              isLoading={demographicsLoading}
              demographics={surveyDemographicsData?.filters}
              totalEngagement={surveyDemographicsData?.engagement}
              error={!!demographicsError}
            />
          </>
        )}
      </>
    );
  };

  return (
    <>
      {surveyDispatchError?.response?.status === 404 && (
        <Card sx={{ padding: "2rem" }}>
          <EmptyState
            title={t("status.noSurvey")}
            info={t("status.detail")}
            href={ProtectedRoutes.LAUNCH}
            actionInfo={t("status.sendSurvey")}
          />
        </Card>
      )}
      {surveyDispatchError?.response?.status !== 404 && renderSurvey()}
    </>
  );
};

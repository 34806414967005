import { Grid } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { ActionPlanService } from "../api/ActionPlanService";
import { DiversityRatioService } from "../api/DiversityRatioService";
import { PipelineService } from "../api/PipelineService";
import { SurveyService } from "../api/SurveyService";
import { TrainingService } from "../api/TrainingService";
import { WorkplaceCultureService } from "../api/WorkplaceCultureService";
import { ActionPlanTodoWidget } from "../components/ActionPlanTodoWidget/ActionPlanTodoWidget";
import { ActionPlanWidget } from "../components/ActionPlanWidget/ActionPlanWidget";
import { BenchmarkingWidget } from "../components/BenchmarkingWidget/BenchmarkingWidget";
import { DiversityRatioWidget } from "../components/DiversityRatioWidget/DiversityRatioWidget";
import { InsightsWidget } from "../components/InsightsWidget/InsightsWidget";
import { PipelineWidget } from "../components/PipelineWidget/PipelineWidget";
import { TrainingLinkCard } from "../components/TrainingLinkCard/TrainingLinkCard";
import { TrainingModuleCard } from "../components/TrainingModuleCard/TrainingModuleCard";
import { WorkplaceCultureWidget } from "../components/WorkplaceCultureWidget/WorkplaceCultureWidget";
import { Typography } from "../components/ui/Typography/Typography";
import { useAuth } from "../hooks/useAuth";
import useTranslation from "../hooks/useTranslation";
import SurveyDispatch from "../models/surveyDispatch";
import TrainingModule from "../models/trainingModule";
import { ActionPlanResponse } from "../types/actionPlan";
import {
  ErrorCodes as DiversityRatioErrorCodes,
  GetDiversityRatioResponse,
} from "../types/diversityRatio";
import { ErrorResponse } from "../types/error";
import {
  ErrorCodes as PipelineErrorCodes,
  PipelineResponse,
} from "../types/pipeline";
import { GetSurveyDispatchResponse } from "../types/survey";
import { TrainingModuleResponse } from "../types/training";
import { SubscriptionActions } from "../types/user";
import {
  GetSurveyChapterInsightResponse,
  GetSurveyScoringDemographicResponse,
} from "../types/workplaceCulture";
import { sortTrainingModules } from "../utils/Training.utils";
import "./DashboardPage.scss";

export const DashboardPage = () => {
  const { t } = useTranslation("dashboardPage");
  const { authInfo } = useAuth();

  const [surveyDispatch, setSurveyDispatch] = useState<SurveyDispatch>();
  const [pipelineErrorCode, setPipelineErrorCode] = useState<string>("");
  const [diversityRatioErrorCode, setDiversityRatioErrorCode] =
    useState<string>("");
  const [trainingModules, setTrainingModules] = useState<TrainingModule[]>();

  const actionPlanService = new ActionPlanService();
  const surveyService = new SurveyService();
  const workplaceCultureService = new WorkplaceCultureService();
  const diversityRatioService = new DiversityRatioService();
  const pipelineService = new PipelineService();
  const trainingService = new TrainingService();

  const {
    data: actionPlanData,
    isLoading: actionPlanLoading,
    error: actionPlanError,
    refetch: handleActionPlanRefetch,
  } = useQuery<ActionPlanResponse, AxiosError>(["actionPlan"], () =>
    actionPlanService.getActionPlan()
  );

  const {
    data: surveyDispatchData,
    isLoading: surveyStatusLoading,
    error: surveyStatusError,
    refetch: handleSurveyDispatchRefetch,
  } = useQuery<GetSurveyDispatchResponse, AxiosError>(["surveyDispatch"], () =>
    surveyService.getSurveyDispatch()
  );

  const {
    data: surveyScoringData,
    isLoading: surveyScoringLoading,
    error: surveyScoringError,
  } = useQuery<GetSurveyScoringDemographicResponse, AxiosError>(
    ["surveyScoring"],
    () =>
      workplaceCultureService.getSurveyScoring(surveyDispatchData?.id || ""),
    {
      enabled: !!surveyDispatchData?.id,
    }
  );

  const {
    data: surveyChapterInsightsData,
    isLoading: surveyChapterInsightsLoading,
    error: surveyChapterInsightsError,
  } = useQuery<GetSurveyChapterInsightResponse, AxiosError>(
    ["surveyChapterInsights"],
    () =>
      workplaceCultureService.getSurveyChapterInsights(
        surveyDispatchData?.id || ""
      ),
    {
      enabled: !!surveyDispatchData?.id,
    }
  );

  const {
    data: diversityRatioData,
    isLoading: diversityRatioLoading,
    error: diversityRatioError,
  } = useQuery<GetDiversityRatioResponse, AxiosError>(
    ["diversityRatio"],
    () => diversityRatioService.getDiversityRatio(),
    { cacheTime: 60 * 60 * 1000 } // increase cache time to 60 minutes
  );

  const {
    data: pipelineData,
    isLoading: pipelineDataLoading,
    error: pipelineDataError,
  } = useQuery<PipelineResponse, AxiosError>(["pipeline"], () =>
    pipelineService.getPipeline()
  );

  const { data: trainingModulesData, error: trainingModulesError } = useQuery<
    TrainingModuleResponse[],
    AxiosError
  >(["trainingModules"], () => trainingService.getTrainingModules(), {
    enabled: true,
  });

  useEffect(() => {
    const errorData = pipelineDataError?.response
      ?.data as ErrorResponse<PipelineErrorCodes>;
    setPipelineErrorCode(errorData?.extra?.code || "");
  }, [pipelineDataError]);

  useEffect(() => {
    const errorData = diversityRatioError?.response
      ?.data as ErrorResponse<DiversityRatioErrorCodes>;
    setDiversityRatioErrorCode(errorData?.extra?.code || "");
  }, [diversityRatioError]);

  useEffect(() => {
    if (surveyDispatchData) {
      setSurveyDispatch(new SurveyDispatch(surveyDispatchData));
    }
  }, [surveyDispatchData]);

  useEffect(() => {
    if (trainingModulesData) {
      const trainingModulesFromResponse = trainingModulesData.map(
        (module) => new TrainingModule(module)
      );

      const { activeModules } = sortTrainingModules(
        trainingModulesFromResponse
      );

      setTrainingModules(activeModules);
    }
  }, [trainingModulesData]);

  useEffect(() => {
    if (trainingModulesError) {
      setTrainingModules([]);
    }
  }, [trainingModulesError]);

  return (
    <div className="DashboardPage">
      <Typography
        className="DashboardPage__title"
        tagVariant="h1"
        desktop="h1"
        data-testid="dashboard-title"
      >
        {t("title")}
        <Typography tagVariant="span" desktop="handwrittenH1" color="error">
          {authInfo.user?.getCompanyName()}?
        </Typography>
      </Typography>
      <Grid container spacing={3} marginBottom={3}>
        {process.env.REACT_APP_FEATURE_ACTION_PLAN === "true" && (
          <Grid
            item
            md={process.env.REACT_APP_FEATURE_TODO === "true" ? 8 : 12}
            className="DashboardPage__widget"
          >
            <ActionPlanWidget
              surveyDispatch={surveyDispatch}
              eventsData={actionPlanData?.events}
              error={!!actionPlanError}
              isLoading={actionPlanLoading}
              onActionPlanUpdate={handleActionPlanRefetch}
            />
          </Grid>
        )}
        {process.env.REACT_APP_FEATURE_TODO === "true" && (
          <Grid item md={4} className="DashboardPage__widget">
            <ActionPlanTodoWidget />
          </Grid>
        )}
        <Grid item sm={12} md={6} lg={4} className="DashboardPage__widget">
          <WorkplaceCultureWidget
            surveyDispatch={surveyDispatch}
            surveyDispatchNoSurvey={surveyStatusError?.response?.status === 404}
            surveyDispatchError={
              !!surveyStatusError && surveyStatusError.response?.status !== 404
            }
            surveyDispatchLoading={surveyStatusLoading}
            onSurveyUpdate={handleSurveyDispatchRefetch}
            scoringHasEnoughAnswers={
              surveyScoringData?.scores.total_score !== null
            }
            scoringData={surveyScoringData}
            scoringError={!!surveyScoringError}
            scoringIsLoading={surveyScoringLoading}
            preview={
              !authInfo.user?.hasAccess(SubscriptionActions.LAUNCH_SURVEY)
            }
          />
        </Grid>
        <Grid item sm={12} md={6} lg={4} className="DashboardPage__widget">
          <BenchmarkingWidget
            inclusionScore={surveyScoringData?.scores.total_score}
            scoreError={!!surveyScoringError}
            scoreLoading={
              surveyDispatch ? surveyScoringLoading : surveyStatusLoading
            }
          />
        </Grid>
        <Grid item sm={12} md={6} lg={4} className="DashboardPage__widget">
          <InsightsWidget
            insightsData={surveyChapterInsightsData}
            insightsLoading={
              (surveyChapterInsightsLoading && !!surveyDispatchData?.id) ||
              surveyStatusLoading
            }
            insightsError={!!surveyChapterInsightsError}
            noInsights={
              surveyChapterInsightsData?.length === 0 ||
              (!surveyDispatchData?.id && !surveyStatusLoading)
            }
            preview={
              !authInfo.user?.hasAccess(SubscriptionActions.LAUNCH_SURVEY)
            }
            surveyClosed={surveyDispatch?.isClosed()}
          />
        </Grid>
        <Grid item sm={12} md={6} lg={4} className="DashboardPage__widget">
          <DiversityRatioWidget
            diversityData={diversityRatioData}
            diversityIsLoading={diversityRatioLoading}
            diversityError={!!diversityRatioError}
            noIntegration={
              diversityRatioErrorCode ===
              DiversityRatioErrorCodes.IntegrationNotFound
            }
            preview={diversityRatioError?.response?.status === 403}
          />
        </Grid>
        <Grid item sm={12} md={12} lg={8} className="DashboardPage__widget">
          <PipelineWidget
            pipelineData={pipelineData}
            pipelineDataLoading={pipelineDataLoading}
            pipelineDataError={!!pipelineDataError}
            noIntegration={
              pipelineErrorCode === PipelineErrorCodes.IntegrationNotFound
            }
            noJobLevel={pipelineErrorCode === PipelineErrorCodes.NoJobLevel}
            preview={pipelineDataError?.response?.status === 403}
          />
        </Grid>
      </Grid>
      <div className="DashboardPage__title">
        <Typography tagVariant="h2" desktop="h1">
          {t("training.title", {
            companyName: authInfo.user?.getCompanyName(),
            interpolation: { escapeValue: false },
          })}
        </Typography>
        <Typography tagVariant="p" desktop="body1" color="secondary">
          {t("training.info")}
        </Typography>
      </div>
      <Grid container spacing={3}>
        {trainingModules?.slice(0, 4).map((trainingModule, i) => {
          return (
            <Grid
              item
              xs={12}
              sm={6}
              md={12 / 5}
              key={`${trainingModule.title}-${i}`}
            >
              <TrainingModuleCard
                trainingModule={trainingModule}
                isActive={trainingModule.isReady}
              />
            </Grid>
          );
        })}
        <Grid item xs={12} sm={6} md={12 / 5} style={{ zIndex: 1 }}>
          <TrainingLinkCard />
        </Grid>
      </Grid>
    </div>
  );
};

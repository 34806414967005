import { Grid, Typography } from "@mui/material";
import useTranslation from "../../hooks/useTranslation";
import {
  GetSurveyChapterScoringResponse,
  SurveyChapterInsight,
  SurveyChapterScoring,
} from "../../types/workplaceCulture";
import { ChapterLinkCard } from "../ChapterLinkCard/ChapterLinkCard";
import "./ChapterOverview.scss";

interface ChapterOverviewProps {
  chapterScoringData?: GetSurveyChapterScoringResponse;
  isLoading?: boolean;
  error?: boolean;
  chapterInsightsData?: SurveyChapterInsight[];
  surveyClosed?: boolean;
}

export const ChapterOverview = ({
  chapterScoringData,
  isLoading = true,
  error = false,
  chapterInsightsData,
  surveyClosed = false,
}: ChapterOverviewProps) => {
  const { t } = useTranslation("workplaceCulturePage");

  const loadingChapter = (id: string): SurveyChapterScoring => {
    return {
      id: id,
      name: "",
      description: "",
      image: "",
      demographic: {
        id: "",
        name: "",
        scores: {
          total_score: 0,
          filters: [],
        },
      },
    };
  };

  // Add fake loading chapters if data has not arrived yet
  const chapters = chapterScoringData
    ? chapterScoringData.chapters
    : [loadingChapter("ld-1"), loadingChapter("ld-2"), loadingChapter("ld-3")];

  const getChapterInsights = (chapterId: string) => {
    return chapterInsightsData
      ?.filter((insight) => insight.chapter_id === chapterId)
      .sort((a, b) => a.order - b.order);
  };
  return (
    <div className="ChapterOverview">
      <Typography variant="handwrittenH1" color="error">
        {t("surveyChapterOverview.title")}
      </Typography>
      <Typography color="secondary">
        {t("surveyChapterOverview.info")}
      </Typography>
      {error && (
        <Typography variant="h3" sx={{ mt: 2 }}>
          {t("surveyChapterOverview.error")}
        </Typography>
      )}
      <Grid container spacing={4} marginTop={1}>
        {!error &&
          chapters.map((chapter) => {
            return (
              <Grid item lg={4} md={6} sm={12} key={chapter.id}>
                <ChapterLinkCard
                  chapter={chapter}
                  isLoading={isLoading}
                  insights={getChapterInsights(chapter.id)}
                  surveyClosed={surveyClosed}
                />
              </Grid>
            );
          })}
      </Grid>
    </div>
  );
};

import classNames from "classnames";
import { Link } from "react-router-dom";
import useTranslation from "../../hooks/useTranslation";
import { SurveyChapterInsight } from "../../types/workplaceCulture";
import { highlightPercentages } from "../../utils/formatters";
import { ProtectedRoutes } from "../../utils/routes.utils";
import { Typography } from "../ui/Typography/Typography";
import "./InsightsSlide.scss";

export interface InsightsSlideProps {
  compact?: boolean;
  insight: SurveyChapterInsight;
  needsTranslation?: boolean;
}

export const InsightsSlide = ({
  compact = false,
  insight,
  needsTranslation,
}: InsightsSlideProps) => {
  const { t } = useTranslation("workplaceCulturePage");
  const insightText = highlightPercentages(
    needsTranslation ? t(insight.insight) : insight.insight,
    "InsightsSlide__accent"
  );

  const classes = classNames("InsightsSlide", {
    "InsightsSlide--compact": compact,
  });

  return (
    <div className={classes} key={insight.id}>
      <Typography desktop={compact ? "body2" : "h3"}>
        <div dangerouslySetInnerHTML={{ __html: insightText }}></div>
      </Typography>
      <Link
        className="InsightsSlide__link"
        to={`${ProtectedRoutes.WORKPLACE_CULTURE}/${insight.chapter_id}`}
      >
        {t("insights.chapterLink")}
      </Link>
    </div>
  );
};
